import React from 'react'
import { InfoSection } from '../../components';
import { Helmet } from 'react-helmet';
import { aboutObjOne, aboutObjTwo } from "./Data";

function Academy() {

    return (
        <>
        <Helmet>
        <title>Academy - BassdropTech</title>
        <meta
          charSet="utf-8"
          name="description"
          content="BassdropTech providing the best set of video tutorials"
        />
        <link rel="canonical" href="https://bassdroptech.com"/>
        </Helmet>
            <InfoSection {...aboutObjOne} />
            <InfoSection {...aboutObjTwo} />
        </>
    );
}

export default Academy
