import React from 'react'
import { InfoSection } from '../../components';
import { Helmet } from 'react-helmet';

function About() {


    const aboutObjOne = {
        primary: false,
        lightBg: true,
        lightTopLine: true,
        lightText: true,
        lightTextDesc: true,
        topLine: '',
        headline: '',
        description:
            [''],
        buttonLabel: '',
        imgStart: 'false',
        img: require('../../images/about.jpg'),
        alt: 'Vault',
        start: 'true'
    };

    return (
        <>
        <Helmet>
        <title>About - BassdropTech</title>
        <meta
          charSet="utf-8"
          name="description"
          content="Matt Johnston is a barber originally from Stockton, California who took a leap of faith and relocated to Hollywood in hopes of finding a high-end clientele."
        />
        <link rel="canonical" href="https://BassdropTech.com"/>
        </Helmet>
            <InfoSection {...aboutObjOne} />
        </>
    );
}

export default About
