import React from "react";
import { homeObjOne, heroObj } from "./Data";
import {
  HeroSection,
  InfoSection,
  PhotoTiles,
  Pricing,
} from "../../components";
import { Helmet } from "react-helmet";

function Home() {
  return (
    <>
      <Helmet>
        <title>Home - BassdropTech</title>
        <meta
          charSet="utf-8"
          name="description"
          content="BassdropTech"
        />
        <link rel="canonical" href="https://bassdroptech.com"/>
      </Helmet>
      {/* <HeroSection {...heroObj} />
      <InfoSection {...homeObjOne} /> */}
      <PhotoTiles />
      {/* <Pricing />  */}
      {/* <InfoSection {...homeObjFour} /> */}
    </>
  );
}

export default Home;
