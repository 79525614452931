import React from "react";
import { Container } from "../../globalStyles";
import {
  PhotoContainer,
  StyledPhoto,
  PhotoSection,
  PhotoWrapper,
  PhotoCard,
} from "./PhotoTiles.elements";

function PhotoTiles({}) {
  return (
    <>
      <PhotoSection>
      <Container>
        <PhotoWrapper>
          <PhotoContainer>
            <PhotoCard>
              <StyledPhoto src={require("./../../images/photo-tile-01_500.jpg")} />
            </PhotoCard>
            <PhotoCard>
              <StyledPhoto src={require("./../../images/photo-tile-02_500.jpg")} />
            </PhotoCard>
          </PhotoContainer>
        </PhotoWrapper>
        
      </Container>
      </PhotoSection>
    </>
  );
}

export default PhotoTiles;
