export const aboutObjOne = {
    primary: false,
    lightBg: false,
    lightTopLine: true,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Academy',
    headline: 'Software Testing Learning',
    description:
        [''],
    buttonLabel: 'Enroll Now',
    imgStart: false,
    img: require('../../images/photo-tile-01_500.jpg'),
    alt: '',
    start: true, 
    buttonLink: ""
};

export const aboutObjTwo = {
    primary: false,
    lightBg: true,
    lightTopLine: false,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Video Tutorials',
    headline: 'Courses Available Online',
    description:['Interative learning at your own pace, with lifetime access to content. New content continuously getting added to help you grow in the industry.'],
    buttonLabel: '',
    imgStart: true,
    img: require('../../images/academy_500.jpg'),
    alt: 'academy',
    start: true
};